import React from 'react';
import {Grid} from '@mui/material';
import _ from 'lodash';
import ReactPlayer from 'react-player/lazy';

const Radio = () => {
  return (
    <Grid container direction="column">
      <Grid item sx={{maxWidth: {xs: 320, sm: '100%', md: '100%', lg: '100%', xl: '100%'}, height: '540px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <ReactPlayer style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} playing volume="0.25" loop controls width="100%" height="100%" url='https://www.youtube.com/watch?v=rIJ_mucuvwQ&list=UUF494AxFwZl2BG3ySp9ehsQ' />
      </Grid>
    </Grid>
  );
}

export default Radio;