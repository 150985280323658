
import React from 'react';
import {BottomNavigation, Stack, Typography} from '@mui/material';
import CustomLink from './customLink';

const Footer = () => {
  const recipes = <CustomLink link='https://www.bareperformancenutrition.com/blogs/content/tagged/recipes' linkName='Recipes'></CustomLink>
  const articles = <CustomLink link='https://www.bareperformancenutrition.com/blogs/content/tagged/articles' linkName='Articles'></CustomLink>
  const podcasts = <CustomLink link='https://www.bareperformancenutrition.com/pages/podcasts' linkName='Podcasts'></CustomLink>
  const youtube = <CustomLink link='https://www.youtube.com/playlist?list=PLEN-mpJU_SWRQas1OaX74Swk_fXA9zrhM' linkName='Youtube'></CustomLink>

  const links = <Typography sx={{display: 'inline'}} variant="body3">{recipes} · {articles} · {podcasts} · {youtube}</Typography>;

  return (
    <BottomNavigation sx={{position: 'fixed', bottom: 0, width: '100%', maxHeight: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Typography  variant="body3" sx={{display: 'inline'}}>Engineered to  <Typography sx={{color: 'primary.main', display: 'inline'}}>go one more</Typography> by <CustomLink link='https://www.danidewitt.com/' linkName='Dani Dewitt' /></Typography>
    </BottomNavigation>
  );
};

export default Footer;
