import React from 'react';
import {Grid, Stack, Typography} from '@mui/material';
import {carbOptions} from '../constants/carbOptions';
import CustomCard from './customCard';
import Carousel from './carousel';
import _ from 'lodash';
import 'react-glidejs/dist/index.css';

const Recommendations = () => {
  const typesOfSupplements = _.groupBy(carbOptions, 'type');
  const fuel = typesOfSupplements['fuel'];

  return (
    <Stack>
      <Typography variant="h6">Fuel the workout</Typography>
      <Carousel>
        {fuel.map((supp, index) => <CustomCard key={index} metadata={supp} />)}
      </Carousel>
    </Stack>
  );
};

export default Recommendations;
