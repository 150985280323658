import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography} from '@mui/material';
import {INITIAL_FUELING_VALUES} from '../constants/initialValues';
import {displayResult, calculatePercentChange, caclulateTotalSweatLoss, calculateSweatRate, calculateSodiumLoss, calculateTotalSodiumLoss, calculateCarbRateResults, calculateTotalCarbResults} from '../utils';
import Results from './results';
import Preworkout from './preworkout';
import Recover from './recover';
import Fuel from './fuel';
import * as yup from 'yup';
import _ from 'lodash';

const validationSchema = yup.object({
  duration: yup.number()
    .min(1, 'Too Short!')
    .positive('Must be positive number')
    .required('Required'),
  startWeight: yup.number()
    .min(3, 'Too Short!')
    .positive('Must be positive number')
    .required('Required'),
  endWeight: yup.number()
    .min(3, 'Too Short!')
    .positive('Must be positive number')
    .required('Required'),
});

const Endurance = () => {
  const [results, setResults] = useState(null);

  const formik = useFormik({
    initialValues: INITIAL_FUELING_VALUES,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const results = calculateResults(values);
      setResults(results);
    },
  });

  const calculateResults = (values) => {
    const activity = values.activity;
    const intensity = values.intensity;
    const startWeight = values.startWeight * 0.453592;
    const endWeight = values.endWeight * 0.453592;
    const liquid = values.liquid * 0.0295735;
    const duration = values.duration;
    const concentration = values.concentration || 500;
    const percentChange = calculatePercentChange(startWeight, endWeight);
    const totalSweatLoss = caclulateTotalSweatLoss(startWeight, endWeight, liquid);
    const sweatRate = calculateSweatRate(totalSweatLoss, duration);
    const sodiumLoss = calculateSodiumLoss(sweatRate, concentration);
    const totalSodiumLoss = calculateTotalSodiumLoss(sodiumLoss, duration);
    const carbLossRate = calculateCarbRateResults(duration, activity, intensity);
    const carbLossTotal = calculateTotalCarbResults(carbLossRate, duration)
    const goOneMoreSportLow = carbLossTotal.low/20;
    const goOneMoreSportHigh = carbLossTotal.high/20;
    const electrolytesLow = (totalSodiumLoss-(goOneMoreSportLow*350))/500;
    const electrolytesHigh = (totalSodiumLoss-(goOneMoreSportHigh*350))/500;
    const goOneMoreSport = _.max([goOneMoreSportLow, goOneMoreSportHigh]);
    const electrolytes = _.max([electrolytesLow, electrolytesHigh]);
    return {
      activity,
      intensity,
      startWeight: displayResult(startWeight),
      endWeight: displayResult(endWeight),
      liquid: displayResult(liquid),
      duration: displayResult(duration),
      concentration: displayResult(concentration),
      percentChange: displayResult(percentChange),
      totalSweatLoss: displayResult(totalSweatLoss),
      sweatRate: displayResult(sweatRate),
      sodiumLoss: displayResult(sodiumLoss),
      totalSodiumLoss: displayResult(totalSodiumLoss),
      carbLossRate,
      carbLossTotal: {
        low: displayResult(carbLossTotal.low),
        high: displayResult(carbLossTotal.high),
      },
      goOneMoreSport: displayResult(goOneMoreSport),
      electrolytes: displayResult(electrolytes),
    }
  }

  return (
    <Grid container spacing={4} direction="row" sx={{pb: 2, display: 'flex', justifyContent: 'center'}}>
      <Grid item xs="auto">
        <Typography variant="h5" sx={{mb: 2}}>Endurance Workout Metrics</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Type of session</Typography>} placement="top" arrow>
                <FormControl fullWidth>
                  <InputLabel id="activity">Activity</InputLabel>
                  <Select
                    labelId="activity"
                    name="activity"
                    id="activity"
                    value={formik.values.activity}
                    label="Activity"
                    onChange={formik.handleChange}
                    size="small"
                  >
                    <MenuItem value='running'>Running</MenuItem>
                    <MenuItem value='cycling'>Cycling</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Duration of the session</Typography>} placement="top" arrow>
                <TextField 
                  label="Duration (min)"
                  id="duration"
                  name="duration"
                  type="number"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Intensity of the session</Typography>} placement="top" arrow>
                <FormControl fullWidth>
                  <InputLabel id="intensity">Intensity</InputLabel>
                  <Select
                    labelId="intensity"
                    id="intensity"
                    name="intensity"
                    value={formik.values.intensity}
                    label="Intensity"
                    size="small"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='low'>Low</MenuItem>
                    <MenuItem value='high'>High</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Weight before the session</Typography>} placement="top" arrow>
                <TextField 
                  label="Start Weight (lb)"
                  id="startWeight"
                  name="startWeight"
                  type="number"
                  value={formik.values.startWeight}
                  onChange={formik.handleChange}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Weight after the session</Typography>} placement="top" arrow>
                <TextField 
                  label="End Weight (lb)"
                  id="endWeight"
                  name="endWeight"
                  type="number"
                  value={formik.values.endWeight}
                  onChange={formik.handleChange}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1">Volume of liquid consumed in session</Typography>} placement="top" arrow>
                <TextField 
                  label="Liquid Consumed (oz)"
                  id="liquid"
                  name="liquid"
                  type="number"
                  value={formik.values.liquid}
                  onChange={formik.handleChange}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={<Typography variant="body1"><Stack>Sweat Test Concentration Result</Stack><Stack>Don't know? 500 mg/l is average</Stack></Typography>} placement="top" arrow>
                <TextField 
                  label="Average Sodium Concentration (mg/l)"
                  id="concentration"
                  name="concentration"
                  type="number"
                  value={formik.values.concentration}
                  onChange={formik.handleChange}
                  size="small"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Button color="primary" variant="contained" type="submit" fullWidth disabled={!formik.dirty || _.keys(formik.errors).length > 0}>
                Go One More
              </Button>
            </Grid>              
          </Grid>
        </form>
      </Grid>
      <Grid item xs="auto">
        <Grid container spacing={1} direction="column">
          <Grid item><Typography variant="h5">Results</Typography></Grid>
          <Grid item><Results results={results}/></Grid>
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Grid container spacing={1} direction="column">
          <Grid item><Typography variant="h5">Fuel the session</Typography></Grid>
          <Grid item><Preworkout /></Grid>
          <Grid item><Fuel /></Grid>
          <Grid item><Recover /></Grid>
        </Grid>
      </Grid> 
    </Grid>
  );
}

export default Endurance;