import {CARBS_BY_WORKOUT} from './constants/carbsByWorkout';

export const displayResult = (result) => (Math.round(result * 10) / 10);
export const calculatePercentChange = (startWeight, endWeight) => ((endWeight - startWeight) / endWeight)*100;
export const caclulateTotalSweatLoss = (startWeight, endWeight, liquid) => ((startWeight - endWeight) + liquid);
export const calculateSweatRate = (sweatLoss, duration) => (60*(sweatLoss/duration));
export const calculateSodiumLoss = (sweatRate, concentration) => (sweatRate*concentration);
export const calculateTotalSodiumLoss = (sodiumLoss, duration) => (sodiumLoss*(duration/60));
export const calculateCarbRateResults = (duration, activity, intensity) => {
  const length = duration > 60 ? 'long' : 'short';
  const result = length === 'long' ? CARBS_BY_WORKOUT[length][activity][intensity] : CARBS_BY_WORKOUT[length];
  return result;
};
export const calculateTotalCarbResults = (carbRate, duration) => {
  return {
    low: carbRate.carbsPerHour.low * duration / 60,
    high: carbRate.carbsPerHour.high * duration / 60,
  };
}