import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Button, Grid, Stack, TextField, Tooltip, Typography} from '@mui/material';
import {displayResult} from '../utils';
import {INITIAL_PACING_VALUES} from '../constants/initialValues';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

const defaultResults = {
  pace: `__:__:__._/mile`,
  duration: `__:__:__._`,
  distance: `__ miles`,
};

const validationSchema = yup.object({
  distance: yup.number(),
  durationHours: yup.number(),
  durationMinutes: yup.number(),
  durationSeconds: yup.number(),
  paceHours: yup.number(),
  paceMinutes: yup.number(),
  paceSeconds: yup.number(),
});

const Pace = () => {
  const [results, setResults] = useState(defaultResults);

  const formik = useFormik({
    initialValues: INITIAL_PACING_VALUES,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const results = calculateResults(values);
      setResults(results);
    },
  });

  const calculateResults = (values) => {
    const durationHours = values.durationHours || 0;
    const durationMinutes = values.durationMinutes || 0;
    const durationSeconds = values.durationSeconds || 0;
    const paceHours = values.paceHours || 0;
    const paceMinutes = values.paceMinutes || 0;
    const paceSeconds = values.paceSeconds || 0;
    const distance = values.distance || 0;

    const durationInSeconds = (durationHours*60*60) + (durationMinutes*60) + durationSeconds;
    const paceInSeconds = (paceHours*60*60) + (paceMinutes*60) + paceSeconds;

    if(!durationInSeconds) {
      const calculatedDurationInMs = distance * paceInSeconds * 1000;
      return {
        pace: `${moment.utc(paceInSeconds*1000).format("HH:mm:ss.S")}/mile`,
        duration: `${moment.utc(calculatedDurationInMs).format("HH:mm:ss.S")}`,
        distance: `${distance} miles`,
      };
    } else if(!distance) {
      const calculatedDistance = durationInSeconds / paceInSeconds; 
      return {
        pace: `${moment.utc(paceInSeconds*1000).format("HH:mm:ss.S")}/mile`,
        duration: `${moment.utc(durationInSeconds*1000).format("HH:mm:ss.S")}`,
        distance: `${displayResult(calculatedDistance)} miles`,
      };
    } else if(!paceInSeconds) {
      const calculatedPaceInMs = durationInSeconds * 1000 / distance;
      return {
        pace: `${moment.utc(calculatedPaceInMs).format("HH:mm:ss.S")}/mile`,
        duration: `${moment.utc(durationInSeconds*1000).format("HH:mm:ss.S")}`,
        distance: `${distance} miles`,
      };
    } else {
      return defaultResults;
    }
  }

  return (
    <Grid container direction="column" xs="auto" sx={{pb: 2}}>
      <Grid item xs="auto">
        <Typography variant="h5" sx={{mb: 2}}>Pace Calculator</Typography>
      </Grid>
      <Grid item xs="auto">
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} direction="column">
              <Grid item xs="12">
                <Grid container spacing={2}>
                  <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                    <Tooltip title={<Stack><Typography variant="body1">Distance - Miles</Typography><Typography variant="body3">Use "0" to Calculate Distance</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Distance (mi)"
                        id="distance"
                        name="distance"
                        type="number"
                        value={formik.values.distance}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>                
                </Grid>
              </Grid>
              <Grid item xs="12">
                <Grid container spacing={2} direction="row">
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Duration - Hours</Typography><Typography variant="body3">Use "0" to Calculate Duration</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Duration (hours)"
                        id="durationHours"
                        name="durationHours"
                        type="number"
                        value={formik.values.durationHours}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>     
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Duration - Minutes</Typography><Typography variant="body3">Use "0" to Calculate Duration</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Duration (min)"
                        id="durationMinutes"
                        name="durationMinutes"
                        type="number"
                        value={formik.values.durationMinutes}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>     
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Duration - Seconds</Typography><Typography variant="body3">Use "0" to Calculate Duration</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Duration (s)"
                        id="durationSeconds"
                        name="durationSeconds"
                        type="number"
                        value={formik.values.durationSeconds}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>            
                </Grid>
              </Grid>
              <Grid item xs="12">
                <Grid container spacing={2} direction="row">
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Pace - Hours</Typography><Typography variant="body3">Use "0" to Calculate Pace</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Pace (hours)"
                        id="paceHours"
                        name="paceHours"
                        type="number"
                        value={formik.values.paceHours}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>     
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Pace - Minutes</Typography><Typography variant="body3">Use "0" to Calculate Pace</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Pace (min)"
                        id="paceMinutes"
                        name="paceMinutes"
                        type="number"
                        value={formik.values.paceMinutes}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>     
                  <Grid item xs="12" sm="4" md="4" lg="4" xl="4">
                    <Tooltip title={<Stack><Typography variant="body1">Duration - Seconds</Typography><Typography variant="body3">Use "0" to Calculate Pace</Typography></Stack>} placement="top" arrow>
                      <TextField 
                        label="Pace (s)"
                        id="paceSeconds"
                        name="paceSeconds"
                        type="number"
                        value={formik.values.paceSeconds}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                      />
                    </Tooltip>
                  </Grid>            
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Button color="primary" variant="contained" type="submit" fullWidth disabled={!formik.dirty || _.keys(formik.errors).length > 0}>
                  Go One More
                </Button>
              </Grid>  
            </Grid>
        </form>
      </Grid>
      <Grid item xs="auto">
        <Typography variant="h5" sx={{mt: 2, mb: 1}}>Results</Typography>
        <Typography variant="h6">Distance: <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.distance}</Typography></Typography>
        <Typography variant="h6">Duration: <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.duration}</Typography></Typography>
        <Typography variant="h6">Pace: <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.pace}</Typography></Typography>
        </Grid>
    </Grid>
  );
}

export default Pace;