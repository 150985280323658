export const CARBS_BY_WORKOUT = {
  short: {
    carbsPerHour: {low: 10, high: 30},
    type: 'low',
  },
  long: {
    cycling: {
      low: {
        carbsPerHour: {low: 50, high: 100},
        type: 'low',
      }, 
      high: {
        carbsPerHour: {low: 50, high: 100},
        type: 'high',
      },
    },
    running: {
      low: {
        carbsPerHour: {low: 25, high: 50},
        type: 'low',
      }, 
      high: {
        carbsPerHour: {low: 25, high: 50},
        type: 'high',
      },
    },
  }
};