export const INITIAL_FUELING_VALUES = {
  previousData: 'no',
  duration: 0,
  intensity: 'low',
  activity: 'running',
  startWeight: 0,
  endWeight: 0,
  liquid: 0,
  concentration: 500,
};

export const INITIAL_PACING_VALUES = {
  distance: 0,
  durationHours: 0,
  durationMinutes: 0,
  durationSeconds: 0,
  paceHours: 0,
  paceMinutes: 0,
  paceSeconds: 0,
};