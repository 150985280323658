import React from 'react';
import {Card, CardActionArea, CardMedia, Tooltip, Typography} from '@mui/material';
import _ from 'lodash';

const CustomCard = ({metadata}) => {
  const tooltip = (
    <>
      <Typography variant="h5">
        {metadata.name}
      </Typography>
      <Typography variant="body3" color="text.secondary" sx={{display: 'block'}}>
        Use to {metadata.description}.
      </Typography>
      {metadata.flavors &&
        <Typography variant="body3" color="text.secondary">
          Flavors: {metadata.flavors.join(', ')}
        </Typography>
      }
    </>
);

  return (
    <Card sx={{ maxWidth: 220, width: 220}}>
      <CardActionArea target="_blank" href={metadata.link}>
        <Tooltip title={tooltip} placement="top" arrow>
          <CardMedia
            component="img"
            height="140"
            image={metadata.photo}
            alt={metadata.name}
          />
        </Tooltip>
      </CardActionArea>
    </Card>
  );
};

export default CustomCard;
