export const carbOptions = [
  {
    link: 'https://www.bareperformancenutrition.com/products/collagen-protein',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_COLLAGEN_1.jpg?crop=center&height=1080&v=1672177166&width=1080',
    name: 'Collagen',
    flavors: ['Unflavored'],
    type: 'prep',
    description: 'support healthy hair, healthy nails, skin elasticity and maintain strong bones, joints and ligaments',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/in-focus',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_INFOC_WATPASSI_1.jpg?crop=center&height=1080&v=1675372487&width=1080',
    name: 'In Focus',
    flavors: ['Watermelon Passionfruit', 'Raspberry Lemonade'],
    type: 'prep',
    description: 'increase in energy, mental focus and delays mental fatigue',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/endo-pump',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_ENDO_BLUERASP_1.jpg?crop=center&height=1080&v=1674487492&width=1080',
    name: 'Endo Pump',
    flavors: ['Blue Raspberry', 'Blackberry Lemonade', 'Sour Watermelon'],
    type: 'prep',
    description: 'supports an increase in blood flow for strong muscle pumps during training',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/flight-pre-workout',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_FLIGHT_BLUERASP_1.jpg?crop=center&height=1080&v=1672738031&width=1080',
    name: 'Flight Pre-Workout',
    flavors: ['Blue Raspberry', 'Green Apple', 'Sour Watermelon', 'Strawberry Kiwi', 'Pink Lemonade'],
    type: 'prep',
    description: 'supports improved endurance, power output and muscle pumps for more effective workouts',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/g-1-m-sport',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_G1MSPORT_FRUITP_1.jpg?crop=center&height=1080&v=1672203470&width=1080',
    name: 'G.1.M Sport',
    flavors: ['Fruit Punch', 'Lemon Lime'],
    type: 'fuel',
    description: 'fuel, hydrate, and improve performance while training',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/field-bar-nutrition-bar',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_FIELDBAR_CPBB_1.jpg?crop=center&height=1080&v=1672176005&width=1080',
    name: 'Field Bar (Whey)',
    flavors: ['Chocolate Peanut Butter Blondie', 'Banana Walnut Bread'],
    type: 'fuel',
    description: 'fuel your day',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/field-bar-plant-based-nutrition-bar',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_FIELDBAR_PBCRUNCH_1.jpg?crop=center&height=1080&v=1675367443&width=1080',
    name: 'Field Bar (Plant)',
    flavors: ['Chocolate Sea Salt', 'Peanut Butter Crunch'],
    type: 'fuel',
    description: 'fuel your day',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/electrolytes',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_ELECTRO_SWATERM_1.jpg?crop=center&height=1080&v=1673303071&width=1080',
    name: 'Electrolytes',
    flavors: ['Lemon Lime', 'Salted Watermelon', 'Strawberry'],
    type: 'fuel',
    description: 'support hydration and performance',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/intra-flight',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_INTRA_WATERMEL_1.jpg?crop=center&height=1080&v=1672207032&width=1080',
    name: 'Intra Flight BCAAs',
    flavors: ['Watermelon', 'Lemonade', 'Blackberry Lime'],
    type: 'fuel',
    description: 'supports improved endurance, increased blood flow, and nutrient delivery',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/whey-protein-powder',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_WHEYPRO_VANILLA_1.jpg?crop=center&height=1080&v=1674487556&width=1080',
    name: 'Whey Protein',
    flavors: ['Cinnamon Roll', 'Chocolate Peanut Butter', 'Blueberry Muffin', 'Fruity Cereal', 'Vanilla', 'Fudge Chocolate', 'Milk N\' Cookies', 'Nutter Bar Blast', 'Apple Pie', 'Strawberry', 'Pumpkin Spice'],
    type: 'recover',
    description: 'improve recovery and build muscle',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/vegan-protein',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_VEGANPRO_OATCOOKIE_1.jpg?crop=center&height=1080&v=1672178702&width=1080',
    name: 'Vegan Protein',
    flavors: ['Oatmeal Cookie', 'Peanut Butter Cookie', 'Chocolate', 'Vanilla'],
    type: 'recover',
    description: 'improve recovery and build muscle',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-food-meal-replacement',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_SFOOD_CINNAMONR_1.jpg?crop=center&height=1080&v=1672176891&width=1080',
    name: 'Strong Food',
    flavors: ['Chocolate', 'Cinnamon Roll'],
    type: 'recover',
    description: 'support high-quality meal replacement'
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/creatine-monohydrate',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_CREATINE_MONO_1.jpg?crop=center&height=1080&v=1672202729&width=1080',
    name: 'Creatine',
    flavors: ['Unflavored'],
    type: 'recover',
    description: 'increase muscle mass, strength, power, and performance',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-greens',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_SGREENS_SGREENS_LEMON_1.jpg?crop=center&height=1080&v=1672164213&width=1080',
    name: 'Strong Greens',
    flavors: ['Lemon', 'Pineapple Coconut'],
    type: 'recover',
    description: 'improve digestion, support the immune system and increase natural energy',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-reds-superfood-powerhouse',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_SREDS__STRAW_1.jpg?crop=center&height=1080&v=1672160327&width=1080',
    name: 'Strong Reds',
    flavors: ['Strawberry'],
    type: 'recover',
    description: 'support the immune system, improve natural energy, and increase nutrient absorption',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-omega-fish-oil-soft-gel',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_STRONG__OMEGA_1.jpg?crop=center&height=1080&v=1674485622&width=1080',
    name: 'Strong Omega',
    type: 'recover',
    description: 'improve heart health and brain health'
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-multi-vitamin',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_STRONG__MULTI_1.jpg?crop=center&height=1080&v=1672162517&width=1080',
    name: 'Strong Multi',
    type: 'recover',
    description: 'optimize your health, energy, mood, and sleep',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/strong-joints-joint-support',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP_STRONG__JOINTS_1.jpg?crop=center&height=1080&v=1672162627&width=1080',
    name: 'Strong Joints',
    type: 'recover',
    description: 'meet the demands of mobility and joint comfort every day',
  },
  {
    link: 'https://www.bareperformancenutrition.com/products/peak-sleep-night-time-sleep-support',
    photo: 'https://cdn.shopify.com/s/files/1/1103/4864/products/BPN_PDP__PSLEEP_CHOC_1.jpg?crop=center&height=1080&v=1675374551&width=1080',
    name: 'Peak Sleep',
    flavors: ['Chocolate', 'Apple Cinnamon', 'Orange Dreamsicle'],
    type: 'recover',
    description: 'improve sleep quality and recovery',
  },
];