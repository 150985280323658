import React, {useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {theme} from './theme';
import {Box, Stack, Tab, Tabs, Typography} from '@mui/material';
import _ from 'lodash';
import Footer from './components/footer';
import Endurance from './components/endurance';
import Pace from './components/pace';
import Radio from './components/radio';
import Tv from './components/tv';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const App = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Box>
          <Stack sx={{mb: 2, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="h4" sx={{textAlign: 'center'}}>Bare Performance Nutrition</Typography>
          </Stack>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs 
              value={value} 
              onChange={handleChange} 
              aria-label="tabs"
              centered
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                },
              }}
            >
              <Tab label="G1M Fuel Calculator" {...a11yProps(0)} />
              <Tab label="G1M Pace Calculator" {...a11yProps(0)} />
              <Tab label="G1M TV" {...a11yProps(1)} />
              <Tab label="G1M Radio" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Endurance />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Stack sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Pace />
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tv />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Radio />
          </TabPanel>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;