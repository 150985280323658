import {createTheme} from '@mui/material/styles';
import BlenderProHeavy from './fonts/Blender-Pro/Blender-Pro-Heavy.woff2';
import BlenderProBook from './fonts/Blender-Pro/Blender-Pro-Book.woff2';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Blender Pro Heavy',
      'Blender Pro Book',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body3: {
      fontFamily: [
        'Blender Pro Book',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Blender Pro Heavy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Blender Pro Heavy'), local('Blender-Pro-Heavy'), url(${BlenderProHeavy}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Blender Pro Book';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Blender Pro Book'), local('Blender-Pro-Book'), url(${BlenderProBook}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ff4438',
    },
  },
});