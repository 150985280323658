import React from 'react';
import {Stack, Tooltip, Typography} from '@mui/material';
import CustomLink from './customLink';

const Results = ({results}) => {

  const electrolytesLink = 'https://www.bareperformancenutrition.com/products/electrolytes';
  const g1mLink = 'https://www.bareperformancenutrition.com/products/g-1-m-sport';
  const plantBarLink = 'https://www.bareperformancenutrition.com/products/field-bar-plant-based-nutrition-bar';
  const wheyBarLink = 'https://www.bareperformancenutrition.com/products/field-bar-nutrition-bar';

  return (
    <Tooltip title={<Typography>Disclaimer: Always consult with your doctor or clincian before taking supplements.</Typography>} placement="top" arrow>
      <Stack sx={{maxWidth: {xs: 300, sm: '100%', md: '100%', lg: '100%', xl: '100%'}}}>
        {results ?
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">Sweat Test</Typography>
            <Typography variant="body3">Body weight change: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.percentChange}%</Typography></Typography>
            <Typography variant="body3">Total sweat loss: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.totalSweatLoss}</Typography> liters</Typography>
            <Typography variant="body3">Sweat rate: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.sweatRate}</Typography> liters per hour</Typography>
            <Typography variant="body3">Sodium loss rate: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.sodiumLoss}</Typography> milligrams per hour</Typography> 
            <Typography variant="body3">Total sodium loss: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.totalSodiumLoss}</Typography> milligrams</Typography>
          </Stack>
          :
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">Sweat Test</Typography>
            <Typography variant="body3">Body weight change: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__%</Typography></Typography>
            <Typography variant="body3">Total sweat loss: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> liters</Typography>
            <Typography variant="body3">Sweat rate: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> liters per hour</Typography>
            <Typography variant="body3">Sodium loss rate: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> milligrams per hour</Typography> 
            <Typography variant="body3">Total sodium loss: <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> milligrams</Typography>
          </Stack>
        }
        {
        results ? 
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">To fuel a <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.duration}</Typography>-minute <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.intensity}</Typography> intensity <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>{results.activity}</Typography> workout</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.totalSweatLoss}</Typography> liters of water</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.totalSodiumLoss}</Typography> milligrams of sodium</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.carbLossRate.carbsPerHour.low}</Typography> to <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.carbLossRate.carbsPerHour.high}</Typography> of carbohydrates (per hour)</Typography>  
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.carbLossTotal.low}</Typography> to <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.carbLossTotal.high}</Typography> of carbohydrates (total)</Typography>  
          </Stack>
          :
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">To fuel a <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>__</Typography>-minute <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> intensity <Typography variant="h6" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> workout</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> liters of water (0.5-1 liters per hour)</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> milligrams of sodium (500-800 mg per liter)</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> to <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> of carbohydrates (per hour)</Typography>
            <Typography variant="body3">Replace <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> to <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> of carbohydrates (total)</Typography>  

          </Stack>
        }
        {results ? 
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">Supplement</Typography>
            {results.goOneMoreSport > 0 && <Typography variant="body3">Fuel with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.goOneMoreSport}</Typography> scoops of <CustomLink link={g1mLink} linkName="G1M Sport" /></Typography>}
            {results.electrolytes > 0 && <Typography variant="body3">Rehydrate with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.electrolytes}</Typography> scoops of <CustomLink link={electrolytesLink} linkName="electrolytes" /></Typography>}
            <Typography variant="body3">Rehydrate with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>{results.totalSweatLoss}</Typography> of water (0.5-1 liters per hour)</Typography>
          </Stack>
        :
          <Stack sx={{mb: 2}}>
            <Typography variant="h6">Supplement</Typography>
            <Typography variant="body3">Fuel with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> scoops of <CustomLink link={g1mLink} linkName="G1M Sport" /></Typography>
            <Typography variant="body3">Rehydrate with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> scoops of <CustomLink link={electrolytesLink} linkName="electrolytes" /></Typography>
            <Typography variant="body3">Rehydrate with <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>__</Typography> of water (0.5-1 liters per hour)</Typography>
          </Stack>
        }
        <Stack sx={{mb: 2}}>
          <Typography variant="h6">BPN Fuel Per Serving</Typography>
          <Typography variant="body3"><CustomLink link={electrolytesLink} linkName="Electrolytes" /> contain <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>500</Typography>mg of sodium</Typography>
          <Typography variant="body3"><CustomLink link={g1mLink} linkName="G1M Sport" /> contains <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>350</Typography>mg of sodium and <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>20</Typography>g of carbs</Typography>
          <Typography variant="body3"><CustomLink link={wheyBarLink} linkName="Whey Field Bars" /> contain <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>30</Typography>mg of sodium and <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>22</Typography>g of carbs</Typography>
          <Typography variant="body3"><CustomLink link={plantBarLink} linkName="Plant-based Field Bars" /> contain <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>340</Typography>mg of sodium and <Typography variant="body3" sx={{color: 'primary.main', display: 'inline'}}>24</Typography>g of carbs</Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default Results;
