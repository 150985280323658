import React, {useRef} from 'react';
import {Box} from '@mui/material';
import _ from 'lodash';
import Glide from 'react-glidejs';
import 'react-glidejs/dist/index.css';

const Carousel = ({children}) => {
  const gliderRef = useRef(null);

  return (
    <Box sx={{maxWidth: {xs: 220, sm: 450, md: 450, lg: 450, xl: 450}}}>
      <Glide
          ref={gliderRef}
          throttle={0}
          type="carousel"
          perView={2}
          startAt={0}
          gap={10}
          focusAt="center"
          breakpoints={
            {
              600: {
                gap: 5,
                perView: 1,
              }
            }
          }
        >
        {children}
      </Glide>
    </Box>
  );
};

export default Carousel;
