import React from 'react';
import {Grid, Stack} from '@mui/material';
import _ from 'lodash';
import ReactPlayer from 'react-player/lazy';

const Tv = () => {
  return (
    <Grid container direction="column">
      <Grid item sx={{maxWidth: {xs: 320, sm: '100%', md: '100%', lg: '100%', xl: '100%'}, height: '540px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <ReactPlayer style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} playing volume="0.25" loop controls width="100%" height="100%" url='https://www.youtube.com/watch?v=G0LQBT_NTfE&list=UUbVEx9qG_hLrb6FrWQJz_Tg' />
      </Grid>
    </Grid>
  );
}

export default Tv;